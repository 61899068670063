.sent-message {
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0;
    text-align: right;
  }
  
  .received-message {
    background-color: #f0f0f0;
    color: #000;
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0;
    text-align: left;
  }
  